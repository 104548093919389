@import '../../../../../../styles/mixins';

.bookItem {
    display: flex;
    flex-basis: 100%;
    max-width: 100%;
    &Body {
        flex: 1;
    }
    &Img {
        margin-right: 2rem;
        img {
            max-width: 8rem;
            min-width: 8rem;
            height: auto;
        }
    }
    h2 {
        font-size: 2rem;
    }
}

@include breakpoint-up(md) {
    .bookItem {
        display: block;
        text-align: center;
        &Img {
            margin-right: 0;
            img {
                margin: 0 auto 1rem auto;
                max-width: 12rem;
                min-width: 12rem;
            }
        }
        h2 {
            font-size: 2.4rem;
        }
    }
}

@include breakpoint-up(lg) {
    .bookItem {
        display: flex;
        text-align: left;
        &Img {
            margin-right: 2rem;
            img {
                margin-bottom: 0;
            }
        }
    }
}
