@import '../../../../../../styles/variables';

.log {
    &Item {
        padding-bottom: 1.2rem;
        border-bottom: 1px solid lighten($gray-1, 5%);
        margin-bottom: 1.5rem;
        &Header {
            display: flex;
            align-items: center;
            &Icon {
                color: $gray-3;
                margin-right: .5rem;
            }
        }
        &Comments {
            margin-top: 1rem;
        }
    }
}


.likeIcon,
.seenIcon {
    width: 2rem;
    height: 2rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: #ccc;
    border-radius: 1rem;
    vertical-align: middle;
    margin-right: 2px;

    svg {
        color: #fff;
        width: 12px;
    }
}

.likeIconActive {
    background: #C33764;
}

.seenIconActive {
    background: $primary;
}