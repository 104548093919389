
*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
    font-size: 62.5%;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
    min-height: 100%;
}

body {
    position: relative;
    font-family: $body-font-family;
    font-size: $base-font-size;
    line-height: $base-line-height;
    text-align: left;
    color: $body-color;
    background: url('../../images/pattern-blue.png');
    @include breakpoint-up(md) {
        font-size: $base-desktop-font-size;
    }
}


.app-container {
    display: flex;
    min-height: 100vh;
    &__content {
        padding: 7rem 1.5rem 12rem 1.5rem;
        flex: 0 0 100%;
        min-height: 100%;
        display: flex;
        @include breakpoint-up(sm) {
            padding: 7rem 3rem 3rem 13rem;
        }
                         @include breakpoint-up(md) {
                             padding: 3rem 3rem 3rem 13rem;
                         }
    }
}

.content-panel  {
    background: #FAFAFA;
    border-radius: 1.6rem;
    padding: 1.5rem;
    flex: 0 0 100%;
    @include breakpoint-up(sm) {
        padding: 3rem;
        border-radius: 3rem;
    }
    @include breakpoint-up(xl) {
        padding: 5rem;
    }
}


// router extra div fix
.app-container__content > div {
    flex-direction: column;
    display: flex;
    flex: 100% 0 0;
}


.page-wrapper {
    flex-direction: column;
    display: flex;
    flex: 100% 0 0;
    &__main {
        order: 1;
        display: flex; //full height on mobile, too
        flex: 100% 0 0;
    }
    &__side {
        order: 0;
    }

}

@include breakpoint-up(md) {
    .page-wrapper {
        flex-direction: row;
        &__main {
            order: 0;
            flex: 0 0 60%;
            max-width: 60%;
            display: flex;
            flex-direction: column;
            padding-right: 3rem;
            margin-bottom: 0;
        }
        &__side {
            order: 1;
            flex: 0 0 40%;
            max-width: 40%;
        }
    }
}

@include breakpoint-up(lg) {
    .page-wrapper {
        &__main {
            order: 0;
            flex-basis: 75%;
            max-width: 75%;
        }
        &__side {
            flex-basis: 25%;
            max-width: 25%;
        }
    }
}
