.resultsList {
    display: flex;
    flex-wrap: wrap;
    margin-left: -1rem;
    margin-right: -1rem;
    flex-direction: row;
}

.bookResult {
    &SelectType {
        display: flex;
        margin-bottom: 2rem;
        flex-direction: row;
        select {
            width: auto;
            margin-bottom: 0;
        }
    }
}
