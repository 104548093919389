hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
  margin-top: 2rem;
  margin-bottom: 2rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
@include breakpoint-up(md) {
  hr {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
}

@each $point in map-keys($grid-breakpoints) {
  $infix: get-infix($point, $grid-breakpoints);
  @include breakpoint-up($point, $grid-breakpoints) {
    @each $prop, $abbr in $space-properties {
      @each $size, $len in $spacers {
        .u-#{$abbr}t#{$infix}-#{$size},
        .u-#{$abbr}y#{$infix}-#{$size} {
          #{$prop}-top: $len !important;
        }

        .u-#{$abbr}b#{$infix}-#{$size},
        .u-#{$abbr}y#{$infix}-#{$size} {
          #{$prop}-bottom: $len !important;
        }
        .u-#{$abbr}#{$infix}-#{$size} {
            #{$prop}: $len !important;
        }
        .u-#{$abbr}l#{$infix}-#{$size} {
            #{$prop}-left: $len !important;
        }
        .u-#{$abbr}r#{$infix}-#{$size} {
            #{$prop}-right: $len !important;
        }
        .u-px#{$infix}-#{$size} {
          padding-right: $len !important;
          padding-left: $len !important;
        }
      }
    }

    .u-m#{$infix}-auto { margin: auto !important; }
    .u-mt#{$infix}-auto,
    .u-my#{$infix}-auto {
      margin-top: auto !important;
    }
    .u-mr#{$infix}-auto,
    .u-mx#{$infix}-auto {
      margin-right: auto !important;
    }
    .u-mb#{$infix}-auto,
    .u-my#{$infix}-auto {
      margin-bottom: auto !important;
    }
    .u-ml#{$infix}-auto,
    .u-mx#{$infix}-auto {
      margin-left: auto !important;
    }
  }
}
