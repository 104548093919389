@import '../../styles/variables';
@import '../../styles/mixins';

.guidance {
    position: relative;
    top: -5.5rem;
    &InfoIcon {
        width: 3rem;
        height: 3rem;
        background: $gray-2;
        border-radius: 5rem;
        line-height: 3rem;
        text-align: center;
        display: inline-block;
        margin-right: 1rem;
        cursor: pointer;
        box-shadow: 0 0 .2rem .1rem rgba(0, 0, 0, .3);
        position: absolute;
        top: 4rem;
        right: 4rem;
        svg {
            margin-top: .3rem;
        }
        &:hover {
            background: $primary;
        }
    }
    &Panel {
        background: $gray-2;
        padding: 2rem;
        border-radius: 2rem;
        position: relative;
        display: none;
        margin-bottom: 2rem;
        &::before {
            content: '';
            width: 0;
            height: 0;
            border-left: 2.4rem solid transparent;
            border-bottom: 2.4rem solid $gray-2;
            border-right: 0rem solid transparent;
            position: absolute;
            right: 7rem;
            bottom: 100%;
        }
    }
    &Avatar {
        max-width: 7rem;
        position: absolute;
        top: 0;
        right: 0;
        border-radius: 50%;
        border: 1px solid #fff;
        background: #ffd653
    }
    &Title {
        margin-bottom: 2rem;
        text-align: center;
        svg {
            vertical-align: middle;
        }
    }
    &Content {
        max-height: 24rem;
        overflow-y: auto;
    }
}

.guidanceMobile {
    position: fixed;
    background: linear-gradient($primary-dark, $primary) no-repeat fixed;
    z-index: 101;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    &Inner {
        background: $secondary;
        min-height: calc(100% - 1rem);
        width: calc(100% - 1.5rem);
        margin: 0.5rem 0 0 1.5rem;
        border-radius: 2.4rem 0 0 2.4rem;
        padding: 1.5rem;
    }
    &Close {
        width: 5.8rem;
        height: 4.8rem;
        background: #fff;
        border-radius: 0 10rem 10rem 0;
        text-align: center;
        padding-top: 1rem;
        position: absolute;
        left: 1.5rem;
        top: 5rem;
        cursor: pointer;
        &:hover {
            color: $primary;
        }
    }
    &AvatarHolder {
        display: flex;
        justify-content: flex-end;
    }
    &Avatar {
        flex-basis: 14rem;
        max-width: 14rem;
        svg {
            width: 100%;
            height: auto;
            border-radius: 50%;
        }
    }

    &Panel {
        display: block;
        background: #fff;
        margin-top: 1rem;
        &::before {
            border-bottom: 2.4rem solid #fff;
            right: 8rem;
        }
    }
}

@include breakpoint-up(md) {
    .guidance {
        top: 0;
        padding-top: 11rem;
        &Panel {
            display: block;
            &::before {
                right: 4.5rem;
            }
        }
        &InfoIcon {
            display: none;
        }
        &Avatar {
            max-width:10rem;
        }
    }
}

@include breakpoint-up(lg) {
    .guidance {
        padding-top: 14.5rem;
        &Panel {
            &::before {
                right: 5rem;
            }
        }
        &Avatar {
            max-width:14rem;
        }
    }
}

@include breakpoint-up(xl) {
    .guidance {
        margin-top: 1rem;
        &Panel {
            &::before {
                right: 10rem;
            }
        }
    }
}
