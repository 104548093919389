// .basic_form__switch_sv {
// 	border: 3px solid red!important;

// }

.basic-form {
	&__group {
        align-items: flex-start;
        display: flex;
		margin-bottom: 20px;
	}
	&__group-block {
        display: block;
		margin-bottom: 20px;
    }
    &__text-label {
        width: 200px;
        display: flex;
        justify-content: space-between;
        margin-right: 5px;
        padding: 6px 0;
    }
	&__text-box,
    &__text-select {
        border: 1px solid darken($gray-2, 10%);
		border-radius: 2rem;
		color: #4A4A4A;
		font-size: 1.6rem;
		line-height: 1.8rem;
		padding: 1.2rem 1.4rem;
		width: 100%;
    	margin-bottom: 1rem;
        background: #fff;
		&:focus {
			box-shadow: none;
			outline: none;
        }
        &:disabled {
            color: lighten(#979797, 20%);
        }
	}
    &__text-select {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='32px' height='32px' fill='%23999999' viewBox='0 0 32 32'%3e%3cpath d='M4.516,6.742L16,18.227L27.484,6.742L31,10.257L16,25.258L1,10.257L4.516,6.742z'/%3e%3c/svg%3e") no-repeat calc(100% - 1.4rem) 50%;
        background-size: 1.4rem;
		padding-right: 3.4rem;
        &:-moz-focusring {
          color: transparent;
          text-shadow: 0 0 0 #ccc;
        }
    }
	&__group--check {
		position: relative;
	}
	&__check-label {
		padding-left: 26px;
	}
	&__check-box {
		position: absolute;
		z-index: -1;
		opacity: 0;
		~ label:before {
			position: absolute;
			left: 0;
			display: block;
			width: 16px;
			height: 16px;
			text-align: center;
			line-height: 15px;
			pointer-events: none;
			content: "";
			user-select: none;
			background: #fff;
			border: 1px solid #979797;
			top: 50%;
			border-radius: 2px;
			transform: translateY(-50%);
			cursor: pointer;
		}
		&:checked ~ label:before {
			content: "";
			color: #fff;
			background: #2BBEFA;
			border-color: #2BBEFA;
		}
		&:checked ~ label:after {
			content: '';
			position: absolute;
			top: 3px;
			left: 3px;
			width: 10px;
			height: 5px;
			border: 2px solid #fff;
			border-width: 0 0 2px 2px;
			-webkit-transform: rotate(-45deg);
			-ms-transform: rotate(-45deg);
			transform: rotate(-45deg);
		}
	}
    &__hint {
        color: red;
        margin: -20px 0 10px;
	}
	&__result-message {
		margin-bottom: 0;
	}
	&__errors {
		align-items: center;
		background: lighten(red, 50%);
		border: 1px solid red;
		color: red;
		display: flex;
		font-size: 16px;
		margin: 20px 0;
		padding: 10px;
		&__icon {
			margin-right: 7px;
		}
	}
	&__switch {
		position: relative;
		height: 4rem;
		width: 7rem;
		display: inline-block;
		vertical-align: middle;
		font-size: 1.4rem;
		.switch-input {
			opacity: 0;
			&:checked ~ .switch-label:before {
				background: $primary;
				border-color: $primary;
				height: 4rem;
				content: 'yes';
				text-align: left;
				padding-left: 1rem;
				color: #fff;
			}
			&:checked ~ .switch-label:after {
				left: calc(100% - 3rem);
				background: #fff;
				content: '';
			}
			&:disabled ~ .switch-label {
				cursor: not-allowed;
			}
			&:disabled:checked ~ .switch-label:before {
				border-color: $gray-2;
			}
			&:disabled ~ .switch-label:before {
				background: $gray-2;
			}
			&:disabled ~ .switch-label:after {
				background: #fff;
			}
		}
		.switch-label {
			cursor: pointer;
			user-select: none;
			background: #fff;
			color: $body-color;
			&:before {
				position: absolute;
				display: block;
				top: 0;
				bottom: 0;
				left: 0;
				content: 'no';
				width: 7rem;
				background: #fff;
				text-align: right;
				padding-right: 1.6rem;
				border: 1px solid $gray-2;
				border-radius: 100px;
				transition: background 200ms ease-in;
				height: 4rem;
				line-height: 2.8;
				font-weight: bold;
			}
			&:after {
				position: absolute;
				display: block;
				top: 1rem;
				left: .6rem;
				content: '';
				width: 2.2rem;
				height: 2.2rem;
				background: $gray-2;
				transition: all 100ms ease-in;
				border-radius: 100px;
			}
		}
	}
}

// switch input translated class
.basic_form__switch_sv {
	.switch-input {
		&:checked ~ .switch-label:before {
			content: 'ja'!important;
		}
	}
	.switch-label {
		&::before {
			content: 'nej'!important;
		}
	}
}