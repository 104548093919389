a[disabled] {
    pointer-events: none;
}

.btn-reset {
    border: 0;
    padding: 0;
    cursor: pointer;
    text-align: left;
    &:focus {
        outline: none;
    }
}
button {
    cursor: pointer;
}

.btn-primary {
    background: $primary;
    border-color: $primary;
    text-transform: uppercase;
    color: #fff;
    border-radius: 1rem;
    font-weight: bold;
    padding: .6rem 1rem;

    &:hover {
        background: $primary;
    }
}
.btn-outline,
.btn-link {
    border: 1px solid lighten($primary, 10%);
    text-transform: uppercase;
    color: lighten($primary, 10%);
    padding: 5px 15px;
    border-radius: 1rem;
    font-weight: bold;
}

.btn-link {
    color: #fff;
    background: lighten($primary, 10%);
}