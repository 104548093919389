.logForm {
    margin-bottom: 3rem;
    &Pages {
        align-items: center;
        margin-bottom: 1rem;
        &Field {
            flex-basis: 8rem;
            max-width: 8rem;
            flex-shrink: 0;
            margin-right: 1rem;
            &:last-of-type {
                margin-left: 1rem;
            }
        }
    }

}
