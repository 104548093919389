@import '../../styles/variables';
@import '../../styles/mixins';

.bookList {
    display: flex;
    flex-wrap: wrap;
    margin-left: -1rem;
    margin-right: -1rem;
    flex-direction: row;
    &Item {
        flex-basis: 100%;
        max-width: 100%;
        padding-left: 1rem;
        padding-right: 1rem;
        text-align: center;
        display: flex;
        &Img {
            margin-bottom: 1rem;
            img {
                margin: auto;
                max-height: 12rem;
                min-height: 12rem;
                width: auto;
            }
        }
        &Title {
            color: #FFF;
            text-shadow: 0 2px 3px rgba(0,0,0,0.6);
            margin-bottom: 1rem;
            font-size: 2rem;
            font-weight: bold;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        &Add {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: row!important;
        }
        &Label {
            background: #FFF;
            border-radius: 15px;
            bottom: 15px;
            font-weight: bold;
            left: 15px;
            padding: 5px 15px;
            position: absolute;
        }
        &Icon {
            position: absolute;
            bottom: 15px;
            right: 15px;
        }
        &Body {
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
        }
    }
}
.readingBandMeta {
    display: inline-block;
    padding-left: 30px;
    position: relative;
}
.readingBandColor {
    border: 1px solid #CCC;
    border-radius: 7px;
    display: block;
    height: 20px;
    left: 5px;
    top: calc(50% - 10px);
    position: absolute;
    width: 20px;
}


@include breakpoint-up(sm) {
    .bookList {
        &Item {
            flex-basis: 50%;
            max-width: 50%;
        }
    }
}

@include breakpoint-up(md) {
    .bookList {
        &Item {
            flex-basis: 100%;
            max-width: 100%;
        }
    }
}

@include breakpoint-up(lg) {
    .bookList {
        &Item {
            flex-basis: 50%;
            max-width: 50%;
        }
    }
}

@include breakpoint-up(xl) {
    .bookList {
        &Item {
            flex-basis: 33%;
            max-width: 33%;
        }
    }
}

.reviewsPanel {
    padding: 1rem;
    background: #fff;
    border-radius: 1rem;
    max-height: 550px;
    overflow: auto;
    &Title {
        text-align: center;
    }
}
.reviewItem {
    margin-bottom: 1rem;
    background: #F1F1F1;
    padding: 1rem;
    border-radius: 1rem;
    display: flex;
    cursor: pointer;

    &Boomer {
        width: 6rem;
        height: 6rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: -1rem;
    }
    &Body {
        flex:1;
        margin-left: 1rem;
    }
    &Head {
    display: flex;
        align-items: center;
        justify-content: space-between;
    }
}