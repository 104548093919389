@import '../../styles/variables.scss';

.tab {
    border-bottom: solid .3rem transparent;
    cursor: pointer;
    font-size: 2rem;
    font-weight: bold;
    padding: 0 0 .4rem 0;
    margin-right: 2rem;
    &:hover {
        color: lighten($body-color, 10%);
    }
}
.active {
    border-bottom-color: $secondary;
}

.disabled {
    color: #979797;
    pointer-events: none;
}
