@import '../../../../styles/mixins';

.searchOptions {
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
    &Scan {
        background: none;
        border: none;
        border-radius: 1.5rem;
        box-shadow: 0 0 5px rgba(0,0,0,0.3);
        cursor: pointer;
        padding: 1rem;
    }
}

@include breakpoint-up(lg) {
    .searchOptions {
        flex-direction: initial;
        align-items: flex-start;

        &Title {
            flex: 1;
            margin-right: 2rem;
        }
        &Scan {
            flex-basis: 14rem;
        }
    }
}
