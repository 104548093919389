.ratingBtn {
    cursor: pointer;
    padding: 0;
    line-height: normal;
    border: none;
    &:focus {
        outline: none;
    }
    &Display {
        cursor: default;
    }
}
