@import '../../../../styles/variables';

.bookHeader {
    padding-bottom: 2.5rem;
    border-bottom: 1px solid lighten($gray-1, 5%);
    margin-bottom: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    img {
        margin-right: 2rem;
        max-width: 14rem;
        max-height: 15rem;
        height: auto;
    }
}
