%card-base {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 240px;
  min-width: 0;
  word-wrap: break-word;
  background-color: #FFF;
  background-clip: border-box;
  border-radius: 1rem;
  flex: 1 0 0;
  position: relative;
}
.card {
    @extend %card-base;
    box-shadow: 0 0.3rem .4rem 0 rgba(0, 0, 0, 0.12);
    &.card-hover {
        color: $body-color;
        transition: all .1s ease-in;
        margin-bottom: 2rem;
        &:hover {
            box-shadow: 0 0.3rem .5rem 0 rgba(0, 0, 0, 0.2);
        }
    }
    .card-header {
        padding: 1.5rem;

    }
    .card-body {
        flex: 1 1 auto;
        padding: 1.5rem;
    }
}
