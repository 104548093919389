
.menu {
	&__link {
		display: block;
		text-decoration: none;
		color: $body-color;
		padding: 1rem;
		background: #fff;
		border-radius: 10rem;
		margin-bottom: 1rem;
		font-weight: bold;
		text-align: center;
		border: none;
		width: 100%;
		cursor: pointer;
		&:focus {
			outline: none;
		}
		&:hover {
			background: $primary;
			color: #fff;
		}
		&--active {
			border-radius: 10rem 0 0 10rem;
			padding-right: 2.6rem;
			width: calc(100% + 1.6rem);
			&:hover {
				background: #fff;
				color: $body-color;
			}
		}
		&--exit {
			position: absolute;
			bottom: 3.4rem;
			color: #fff;
			padding: 0;
			left: 50%;
			transform: translateX(-50%);
			cursor: pointer;
			border: 0;
			height: auto;
			svg {
				width: 4rem;
			}
			&:hover {
				color: $primary;
			}
		}
	}

	@include breakpoint-up(xl) {
		&__link {
			&--active {
				padding-right: 5rem;
				width: calc(100% + 4rem);
			}
		}
	}
}

.menu-sidebar {
	z-index: 100;
	position: fixed;
	padding: 1.6rem;
	top: 0;
	height: 100%;
	width: 15rem;
	left: 0;
	display: none;
	&__brand {
		margin-bottom: 2rem;
		img {
			max-width: 10rem;
		}
	}
	&__nav {
		text-align: center;
		padding-bottom: 6rem;
	}

}

.menu-header {
	z-index: 100;
	position: absolute;
	padding-top: 1rem;
	top: 0;
	height: 6rem;
	margin-bottom: 1rem;
	width: 100%;
	left: 0;
	padding-left: 1.5rem;
	&__brand {
		cursor: pointer;
		border: 1px solid #fff;
		border-left: none;
		width: 8rem;
		padding: .4rem;
		border-radius: 0 2rem 2rem 0;
		margin-left: -1.5rem;
		&__toggle {
			width: 2rem;
			height: 2.2rem;
			display: inline-block;
			background-image: radial-gradient(circle, #fff 2px, transparent 3px);
			background-size: 100% 33.33%;
			vertical-align: middle;
		}
		img {
			max-width: 4rem;
			display: inline-block;
			vertical-align: middle;
		}
	}
	&__nav {
		display: flex;
		flex-wrap: nowrap;
		width: 100%;
		background: linear-gradient($primary-dark, $primary) no-repeat fixed;
		position: fixed;
		left: 0;
		right: 0;
		bottom: 0;
		top: 0;
		&__links {
			flex-basis: 10rem;
			flex-shrink: 0;
			padding: 1.5rem;
			position: relative;
		}
		&__content {
			background: #fff;
			flex: 1;
			padding: 1.5rem;
			display: flex;
			flex-direction: column;
			&__top {
				display: flex;
				align-items: top;
				margin-bottom: 2rem;
				justify-content: space-between;
				align-items: flex-start;
				&__img {
					flex-basis: 14rem;
					max-width: 14rem;
					border-radius: 2rem;
				}
				&__close {
					width: 4.8rem;
					height: 4.8rem;
					color: #fff;
					background: darken($primary, 15%);
					display: flex;
					align-items: center;
					justify-content: center;
					border-radius: 5rem;
					cursor: pointer;
					flex-shrink: 0;
				}
			}
			&__base {
				margin-top: auto;
			}
		}
	}
}

@include breakpoint-up(md) {
	.menu-header {
		display: none;
	}
	.menu-sidebar {
		display: block;
	}
}

@include breakpoint-up(xl) {
	.menu-sidebar {
		width: 20rem;
		padding: 2.4rem 4rem;
	}
}
