@import '../../styles/variables';
@import '../../styles/mixins';

.menu {
    background: #4E406E;
    z-index: 100;
    position: fixed;
    height: 8rem;
    bottom: 0;
    width: 100%;
    right: 0;
    display: flex;

    &Brand {
        padding: 1rem;
        display: none;

        img {
            width: 5rem;
            margin: auto;
        }
    }

    &Content {
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex: 1;
    }

    &Nav {
        text-align: center;
        display: flex;
        justify-content: space-around;
        flex-direction: row;

        &Item {
            margin-bottom: 1px;
            display: block;
            color: #fff;
            text-transform: uppercase;
            font-weight: bold;
            font-size: 1.2rem;
            display: block;
            padding: 1rem;
            width: 85px;
            height: 85px;
            border: none;
            cursor: pointer;

            &:hover {
                background-color: #3c3253;
            }

            &Home {
                background: url('../../images/menu/home.png') no-repeat top 1rem center;
                background-size: 35px;
                padding-top: 55px;
            }

            &Write {
                background: url('../../images/menu/write.png') no-repeat top 1.5rem center;
                background-size: 45px;
                padding-top: 55px;
            }

            &Read {
                background: url('../../images/menu/read.png') no-repeat top 1rem center;
                background-size: 35px;
                padding-top: 55px;
            }

            &Rewards {
                background: url('../../images/menu/rewards.png') no-repeat top 1.7rem center;
                background-size: 45px;
                padding-top: 55px;
            }

            &Boomer {
                background: url('../../images/menu/boomer.png') no-repeat top 1rem center;
                background-size: 30px;
                padding-top: 55px;
            }
        }

        &Exit {
            margin-top: auto;
            display: flex;
            &Btn {
                background: url('../../images/menu/logout.png') no-repeat top 1rem center;
                background-size: 35px;
                padding-top: 55px;
                border: none;
                cursor: pointer;
                display: block;
                width: 100%;
            }
        }
    }
}

@include breakpoint-up(sm) {
    .menu {
        top: 0;
        height: 100%;
        width: 10rem;
        left: 0;
        bottom: auto;
        right: auto;

        &Content {
            flex-direction: column;
            justify-content: flex-start;
        }

        &Brand {
            display: block;
        }

        &Nav {
            flex-direction: column;

            &Item {
                width: 100%;
                height: unset;

                &Home {
                    background: url('../../images/menu/home.png') no-repeat top 1rem center;
                    background-size: 55px;
                    padding-top: 70px;
                }

                &Write {
                    background: url('../../images/menu/write.png') no-repeat top 1.2rem center;
                    background-size: 60px;
                    padding-top: 70px;
                }

                &Read {
                    background: url('../../images/menu/read.png') no-repeat top 1rem center;
                    background-size: 50px;
                    padding-top: 70px;
                }

                &Rewards {
                    background: url('../../images/menu/rewards.png') no-repeat top 1.7rem center;
                    background-size: 60px;
                    padding-top: 70px;
                }

                &Boomer {
                    background: url('../../images/menu/boomer.png') no-repeat top 1rem center;
                    background-size: 45px;
                    padding-top: 70px;
                }
            }

            &Exit {
                flex-direction: column;
                &Btn {
                    background-size: 45px;
                    padding-top: 75px;
                }
            }
        }
    }
}