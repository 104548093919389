@import '../../../../../../styles/mixins.scss';
@import '../../../../../../styles/variables.scss';

.help {
    text-align: center;
}

.helpSmall {
    display: block;
    font-size: 12px;
}
.isbnHolder {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.vid {
    margin: 20px auto;
    position: relative;
    video {
        background: #000 url('../../../../../../images/loader.svg') center center no-repeat;
        background-size: 40px 40px;
    }
    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        height: 100%;
        width: 100%;
        background: url('../../../../../../images/scannerOverlay.png') center center no-repeat;
        background-size: cover;
    }
}

@include breakpoint-down(xs) {
    .scannerHolder {
        &::after {
            background: url('../../../../../../images/scannerOverlayMob.png') center center no-repeat;
        }
    }
}